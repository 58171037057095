import IconArchIndices from "../../assets/images/home/arch-indices.svg";
import IconAvatrade from "../../assets/images/home/avatrade.svg";
import IconCBOE from "../../assets/images/home/CBOE.svg";
import IconCirca5000 from "../../assets/images/home/circa5000.svg";
import IconFlowBank from "../../assets/images/home/flowbank.svg";
import IconFreeCapFinancial from "../../assets/images/home/free-cap-financial.svg";
import IconFundment from "../../assets/images/home/fundment.svg";
import IconGlobalx from "../../assets/images/home/globalx.svg";
import IconLeverageShares from "../../assets/images/home/leverageshares.svg";
import IconMelanion from "../../assets/images/home/melanion.svg";
import IconPacerAdvisors from "../../assets/images/home/pacer-advisors.svg";
import IconPepperstone from "../../assets/images/home/pepperstone.svg";
import IconPlus500 from "../../assets/images/home/plus500.svg";
import IconQuantumrock from "../../assets/images/home/quantumrock.svg";
import IconRexShares from "../../assets/images/home/rexshares.svg";
import IconSix from "../../assets/images/home/six.svg";
import IconStoneX from "../../assets/images/home/stonex.svg";
import IconSubtract from "../../assets/images/home/subtract.svg";
import IconTuttleCapital from "../../assets/images/home/tuttle-capital.svg";
import IconXm from "../../assets/images/home/xm.svg";

export const CLIENT_LIST = [
  {
    id: 0,
    label: "Six",
    image: IconSix,
  },
  {
    id: 1,
    label: "Plus500",
    image: IconPlus500,
  },
  {
    id: 2,
    label: "GlobalX",
    image: IconGlobalx,
  },
  {
    id: 3,
    label: "IG",
    image: IconSubtract,
  },
  {
    id: 4,
    label: "StoneX",
    image: IconStoneX,
  },
  {
    id: 5,
    label: "CBOE",
    image: IconCBOE,
  },
  {
    id: 6,
    label: "Pacer Advisors",
    image: IconPacerAdvisors,
  },
  {
    id: 7,
    label: "XM",
    image: IconXm,
  },
  {
    id: 8,
    label: "Circa5000",
    image: IconCirca5000,
  },
  {
    id: 9,
    label: "PepperStone",
    image: IconPepperstone,
  },
  {
    id: 10,
    label: "Malanion Capital",
    image: IconMelanion,
  },
  {
    id: 11,
    label: "Leverage Shares",
    image: IconLeverageShares,
  },
  {
    id: 12,
    label: "Flow Bank",
    image: IconFlowBank,
  },
  {
    id: 13,
    label: "AvaTrade",
    image: IconAvatrade,
  },
  {
    id: 14,
    label: "Rex Shares",
    image: IconRexShares,
  },  
  {
    id: 15,
    label: "QuantumRock",
    image: IconQuantumrock,
  },
  {
    id: 16,
    label: "Arch Indices",
    image: IconArchIndices,
  },
  {
    id: 17,
    label: "FreeCap Financial",
    image: IconFreeCapFinancial,
  },
  {
    id: 18,
    label: "Fundment",
    image: IconFundment,
  },
  {
    id: 19,
    label: "Tuttle Capital",
    image: IconTuttleCapital,
  },
];

export const CLIENT_LIST_DESKTOP = [
  [
    [
      {
        id: 0,
        label: "Six",
        image: IconSix,
      },
      {
        id: 1,
        label: "Plus500",
        image: IconPlus500,
      },
      {
        id: 2,
        label: "GlobalX",
        image: IconGlobalx,
      },
      {
        id: 3,
        label: "IG",
        image: IconSubtract,
      },
    ],
    [
      {
        id: 4,
        label: "StoneX",
        image: IconStoneX,
      },
      {
        id: 5,
        label: "Cboe",
        image: IconCBOE,
      },
      {
        id: 6,
        label: "Pacer ETFs",
        image: IconPacerAdvisors,
      },
      {
        id: 7,
        label: "XM",
        image: IconXm,
      },
    ],
  ],
  [
    [
      {
        id: 8,
        label: "Circa5000",
        image: IconCirca5000,
      },
      {
        id: 9,
        label: "PepperStone",
        image: IconPepperstone,
      },
      {
        id: 10,
        label: "Malanion Capital",
        image: IconMelanion,
      },
      {
        id: 11,
        label: "Leverage Shares",
        image: IconLeverageShares,
      },
    ],
    [
      {
        id: 12,
        label: "Flow Bank",
        image: IconFlowBank,
      },
      {
        id: 13,
        label: "AvaTrade",
        image: IconAvatrade,
      },
      {
        id: 14,
        label: "Rex Shares",
        image: IconRexShares,
      },
      {
        id: 15,
        label: "QuantrumRock",
        image: IconQuantumrock,
      },
    ]
  ],
  [
    [ 
      {
        id: 16,
        label: "Arch Indices",
        image: IconArchIndices,
      },
      {
        id: 17,
        label: "FreeCap Financial",
        image: IconFreeCapFinancial,
      },
      {
        id: 18,
        label: "Fundment",
        image: IconFundment,
      },
      {
        id: 19,
        label: "Tuttle Capital",
        image: IconTuttleCapital,
      },
    ]
  ]
];
